<template>
<div>
    <v-container class="contact_box" fluid v-if="show">
        <h1 class="text-center mb-10 page_title f-14-xs">お問合せ･ご予約</h1>

        <p class="f-12 mb-10 text-center">「お問合せ」または「ご予約」いずれかをご選択ください</p>
        <v-row class="mb-14 contact_btn_box relative">
            <v-col cols="6" xl="6" lg="6" md="6" sm="6">
                <v-btn class="ma-2 f-11" outlined color="indigo" x-large block @click="booking=1">
                    お問合せ
                </v-btn>
            </v-col>
            <v-col cols="6" xl="6" lg="6" md="6" sm="6">
                <v-btn class="ma-2 f-11" outlined color="error" x-large block @click="booking=2">
                    ご予約
                </v-btn>
            </v-col>
        </v-row>

        <v-expand-transition>
            <div v-show="booking==1">
                <br>
                <div class="f-12 bold mb-12 ml-2">
                    <router-link to="/faq" target="_blank">
                        <v-icon class="blue--text text--accent-3 f-22 mr-2">fas fa-comments</v-icon>
                        <span class="blue--text text--accent-3">よくある質問</span><span class="f-gray f-10 pl-2">はこちら</span>
                        <v-icon class="f-gray ml-2 link_icon relative">fas fa-external-link-alt</v-icon>
                    </router-link>
                </div>

                <div class="f-12 bold my-6 mb-12 ml-2 mt-12">

                    <v-icon class="amber--text text--darken-3 f-22 mr-2">fas fa-phone-alt</v-icon>
                    <span class="amber--text text--darken-3">お電話でのお問合せ</span><span class="f-gray f-10 pl-2">はこちら</span>
                    <div class="f-gray f-20 bold ml-10 mt-2">083-786-0458</div>
                </div>

                <v-form ref="form1">
                    <h3 class="text-center f-gray mt-16 pt-8 mb-4 f-14">お問合せフォーム</h3>
                    <v-text-field v-model="name" :rules="requredRules" clearable label="お名前*" required type="text" maxlength="255" />

                    <v-text-field v-model="zip" :rules="zipRules" clearable label="郵便番号" type="text" maxlength="8" class="zip" />

                    <v-text-field v-model="address" clearable label="住所" type="text" maxlength="255" />

                    <v-text-field v-model="email" v-if="booking==1" :error-messages="emailErrors" clearable label="E-mail*" maxlength="255" required @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>

                    <v-text-field v-model="tel" clearable label="TEL" type="text" maxlength="15" class="tel" />

                    <v-textarea v-model="text" label="お問合せ内容*" :rules="requredRules" class="mt-5" auto-grow outlined rows="12" row-height="15" maxlength="5000"></v-textarea>

                    <v-btn color="#0D47A1" x-large dark class="comformBtn" required @click="submit()">送信内容の確認</v-btn>
                </v-form>
            </div>
        </v-expand-transition>

        <v-expand-transition>
            <div v-show="booking==2">
                <v-card class="px-6 py-4 mb-16  noselect">
                    <div class="text-center f-12">ご予約状況</div>
                    <p class="f-red f-09 mt-2 text-center">カレンダーの「残りわずか」の日または当日に ご予約の場合は<br class="disno-xs">お電話にてご連絡いただけますとスムーズにご対応できます
                    </p>
                    <CalendarColor />
                    <div>
                        <div class="float-left">
                            <v-btn icon class="ma-2" @click="$refs.calendar1.prev();setEvent()" :class="{opacity0:toMonth==month,noevent:toMonth==month}">
                                <v-icon>fas fa-chevron-left</v-icon>
                            </v-btn>
                            {{month}}
                            <v-btn icon class="ma-2" @click="$refs.calendar1.next();setEvent()" :class="{opacity0: nextMonth==month,noevent:nextMonth==month}">
                                <v-icon>fas fa-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <v-calendar class="noevent" ref="calendar1" :events="events" :event-text-color="getColor" v-model="value" locale="ja-jp" :day-format="(timestamp) => new Date(timestamp.date).getDate()" :month-format="(timestamp) => (new Date(timestamp.date).getMonth() + 1) + ' /'">

                    </v-calendar>

                </v-card>

                <div class="f-12 bold my-6 mb-12 ml-2">
                    <router-link to="/faq" target="_blank">
                        <v-icon class="blue--text text--accent-3 f-22 mr-2">fas fa-comments</v-icon>
                        <span class="blue--text text--accent-3">よくある質問</span><span class="f-gray f-10 pl-2">はこちら</span>
                        <v-icon class="f-gray ml-2 link_icon relative">fas fa-external-link-alt</v-icon>
                    </router-link>
                </div>

                <div class="f-12 bold my-6 mb-12 ml-2 mt-12">

                    <v-icon class="amber--text text--darken-3 f-22 mr-2">fas fa-phone-alt</v-icon>
                    <span class="amber--text text--darken-3">お電話でのご予約</span><span class="f-gray f-10 pl-2">はこちら</span>
                    <div class="f-gray f-20 bold ml-10 mt-2">083-786-0458</div>
                </div>
                <v-form ref="form2">
                    <h3 class="text-center f-gray mt-16 pt-8 mb-4 f-14">ご予約フォーム</h3>
                    <v-text-field v-model="name" :rules="requredRules" clearable label="お名前*" required type="text" maxlength="255" />

                    <v-text-field v-model="zip" :rules="zipRules" clearable label="郵便番号" type="text" maxlength="8" class="zip" />

                    <v-text-field v-model="address" clearable label="住所*" type="text" maxlength="255" :rules="requredRules" />

                    <v-text-field v-model="email" v-if="booking==2" :error-messages="emailErrors" clearable label="E-mail*" maxlength="255" required @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>

                    <v-text-field v-model="tel" clearable label="TEL*" type="text" maxlength="15" class="tel" :rules="requredRules" />

                    <p class="mt-2">【 ご利用人数 】</p>
                    <v-text-field v-model="otona" clearable label="高校生以上*" type="number" maxlength="2" class="people float-left mr-3" :rules="requredRules" />
                    <v-text-field v-model="kodomo" clearable label="３歳～中学生" type="number" maxlength="2" class="people float-left mx-3" />
                    <v-text-field v-model="akachan" clearable label="３歳未満" type="number" maxlength="2" class="people float-left mx-3" />
                    <div class="clear"></div>
                    <v-text-field v-model="car" clearable label="駐車場のご利用台数" type="number" maxlength="2" class="people my-2" />
                    <div class="pointer people  float-left mr-6" @click="daySelect(0)">
                        <v-text-field v-model="firstDay" clearable label="チェックイン*" type="text" class="noevent" :rules="requredRules" readonly />
                    </div>
                    <div class="pointer people  float-left" @click="daySelect(1)">
                        <v-text-field v-model="lastDay" clearable label="チェックアウト*" type="text" class="noevent" :rules="requredRules" readonly />
                    </div>
                    <div class="clear"></div>

                    <p class="f-red f-09" v-html="warning"></p>

                    <v-dialog v-model="dayModal" width="600" height="600" persistent>

                        <v-card class="px-6 py-4 noselect">
                            <div id="calendar ">
                                <div class="text-center py-2">ご希望の日をクリックしてください</div>
                                <CalendarColor />
                                <div>
                                    <div class="float-left">
                                        <v-btn icon class="ma-2" @click="$refs.calendar2.prev();setEvent()" :class="{opacity0:toMonth==month||first==1&&firstMonth==month,noevent:toMonth==month||first==1&&firstMonth==month}">
                                            <v-icon>fas fa-chevron-left</v-icon>
                                        </v-btn>
                                        {{month}}
                                        <v-btn icon class="ma-2" @click="$refs.calendar2.next();setEvent()" :class="{opacity0: nextMonth==month,noevent:nextMonth==month}">
                                            <v-icon>fas fa-chevron-right</v-icon>
                                        </v-btn>
                                    </div>
                                    <div class="folat-right error--text f-09">{{error}}</div>
                                    <div class="clear"></div>
                                </div>
                                <v-calendar ref="calendar2" :events="events" :event-text-color="getColor" v-model="value" @click:date="viewDay" locale="ja-jp" :day-format="(timestamp) => new Date(timestamp.date).getDate()" :month-format="(timestamp) => (new Date(timestamp.date).getMonth() + 1) + ' /'">

                                </v-calendar>
                            </div>
                        </v-card>
                    </v-dialog>
                    
                     <v-select :items="times" v-model="time" clearable label="到着予定時刻*" type="text" maxlength="15" class="people" :rules="requredRules" />

                    <v-textarea v-model="text" label="備考（ジェットスキー・ボート利用の有無、その他ご質問等）" class="mt-5" auto-grow outlined rows="12" row-height="15" maxlength="5000" placeholder="（ジェットスキー・ボート利用の有無、その他ご質問等）"></v-textarea>

                    <v-btn color="#0D47A1" x-large dark class="comformBtn" required @click="submit()">送信内容の確認</v-btn>
                </v-form>
            </div>
        </v-expand-transition>

    </v-container>

    <v-dialog v-model="dialog" persistent width="600">
        <v-card class="pa-5" v-show="card">
            <v-btn color="green darken-1" text @click="dialog = false" class="mdiClose f-20">×</v-btn>
            <h5 class="f-blue f-16 mb-5">送信内容の確認</h5>
            <div class="comfromText">

                <p><b>お名前</b></p>
                <p>{{name}}</p>
                <hr />
                <div v-show="zip==null || zip==''?false:true">
                    <p><b>郵便番号</b></p>
                    <p>{{zip}}</p>
                    <hr />
                </div>
                <div v-show="address==null || address==''?false:true">
                    <p><b>住所</b></p>
                    <p>{{address}}</p>
                    <hr />
                </div>
                <p><b>E-mail</b></p>
                <p>{{email}}</p>
                <hr />
                <div v-show="tel==null || tel==''?false:true">
                    <p><b>TEL</b></p>
                    <p>{{tel}}</p>
                    <hr />
                </div>
                <div v-show="booking==2">
                    <b>【 ご利用人数 】</b>
                    <v-row>
                        <v-col cols="4" xl="4" lg="4" md="4" sm="4" class="py-0 my-0">
                            <p><b>高校生以上</b></p>
                            <p>{{otona}}人</p>
                        </v-col>
                        <v-col cols="4" xl="4" lg="4" md="4" sm="4" v-show="kodomo==null || kodomo==''?false:true" class="py-0 my-0">
                            <p><b>３歳～中学生</b></p>
                            <p>{{kodomo}}人</p>
                        </v-col>
                        <v-col cols="4" xl="4" lg="4" md="4" sm="4" v-show="akachan==null || akachan==''?false:true" class="py-0 my-0">
                            <p><b>３歳未満</b></p>
                            <p>{{akachan}}人</p>
                        </v-col>
                    </v-row>
                    <hr />
                    <div v-show="car==null || car==''?false:true">
                        <p><b>駐車場のご利用台数</b></p>
                        <p>{{car}}台</p>
                        <hr />
                    </div>
                    <b>【 ご利用日程 】</b>
                    <v-row>
                        <v-col cols="6" xl="6" lg="6" md="6" sm="6" class="py-0 my-0">
                            <p><b>チェックイン</b></p>
                            <p>{{checkin}}</p>
                        </v-col>
                        <v-col cols="6" xl="6" lg="6" md="6" sm="6" class="py-0 my-0">
                            <p><b>チェックアウト</b></p>
                            <p>{{checkout}}</p>
                        </v-col>
                    </v-row>
                    <hr />
                    <div>
                        <p><b>到着予定時刻</b></p>
                        <p>{{time}}</p>
                        <hr />
                    </div>
                </div>
                
                <div v-show="text==null || text==''?false:true">
                    <p><b>{{booking==1?"お問合せ内容":"備考"}}</b></p>
                    <p style="white-space: pre-wrap;">{{text}}</p>
                    <hr />
                </div>
                <v-btn color="#0D47A1" x-large dark class="sendBtn" required @click="send">送信する</v-btn>

            </div>

        </v-card>
        <div class="cover" v-show="cover"></div>
        <v-progress-circular v-show="load" indeterminate color="#E64A19" class="fixed-center"></v-progress-circular>
        <v-card class="text-center pa-5 noevent" v-show="thank">
            送信しました<br>
            お問合せありがとうございます
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import d from '@/d'
import axios from "axios"
import zip from '@/mixins/zip'
import moment from 'moment';
import calendar from '@/mixins/calendar'
import {
    validationMixin
} from 'vuelidate'
import {
    required,
    email,
} from 'vuelidate/lib/validators'

import CalendarColor from '@/components/CalendarColor.vue';

export default {
    mixins: [validationMixin, zip, calendar],

    name: "Contact",
    validations: {
        email: {
            required,
            email
        },
    },
    data() {
        return {
            checkin: "",
            checkout: "",
            warning: "",
        }
    },
    computed: {
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push('メールアドレスの形式が違います');
            !this.$v.email.required && errors.push('入力必須です');
            return errors
        },
    },
    components: {
        CalendarColor
    },

created(){
let times=[]

for(let i=9;i<=17;i++){
times.push(i+':00 頃')
if(i==17){break}
times.push(i+':30 頃')
}
times.push("未定")

this.times=times
},
    methods: {
        submit() {
            this.$refs['form' + this.booking].validate()
            this.$v.$touch()
            if (!this.$v.$invalid && this.$refs['form' + this.booking].validate()) {
                this.checkin = moment(this.firstDay).format("yyyy年 M月 D日")
                this.checkout = moment(this.lastDay).format("yyyy年 M月 D日")
                this.dialog = true
                this.card = true
            }
        },

        async send() {

            this.cover = true
            this.load = true

            try {

                await axios.post('/send', {
                    company: this.company,
                    zip: this.zip,
                    address: this.address,
                    name: this.name,
                    email: this.email,
                    tel: this.tel,
                    text: this.text,
                    booking: this.booking,
                    otona: this.otona,
                    kodomo: this.kodomo,
                    akachan: this.akachan,
                    car: this.car,
                    checkin: this.checkin,
                    checkout: this.checkout,
                    time: this.time,
                })

            } catch (error) {
                alert('送信失敗しました')
                this.load = false
                this.cover = false
                return false
            }
            this.load = false
            this.card = false
            this.thank = true
            await d.sleep(2000)
            this.$refs['form' + 1].reset()
            this.$refs['form' + 2].reset()
            this.$v.$reset()
            this.thank = false
            this.dialog = false
            this.cover = false
            await d.sleep(50)
            this.car = 1
            this.otona = 1
            this.kodomo = 0
            this.akachan = 0
            this.firstDay = moment()
                .add(1, "d")
                .format("yyyy-MM-DD")
            this.lastDay = moment()
                .add(1, "d")
                .format("yyyy-MM-DD")

        },

        viewDay(val) {
            this.setEvent()
            if (this.first == 0) {
                this.firstDay = val.date
                this.lastDay = this.firstDay
                this.firstMonth = moment(this.firstDay).format('yyyy年 M月')
            } else {

                if (moment(val.date).isBefore(moment(this.firstDay))) {
                    this.error = "チェックインより前の日付です"
                    return false
                }
                const start = new Date(moment().add(1, "d").format(this.firstDay));
                const end = new Date(moment().format(val.date));

                let loop = new Date(start);
                while (loop <= end) {
                    if (this.fulldays.indexOf(moment(loop).format('yyyy-MM-DD')) != -1) {
                        this.error = "ご利用期間中に「空きなし」の日が含まれています"
                        return false
                    } else if (this.offs.indexOf(moment(loop).format('yyyy-MM-DD')) != -1) {
                        this.error = "ご利用期間中に「シーズンオフ」の日が含まれています"
                        return false
                    } else if (this.nearFulldays.indexOf(moment(loop).format('yyyy-MM-DD')) != -1) {
                        this.warning = "ご利用期間中に「残りわずか」の日が含まれます。<br>お電話にてご連絡いただけますとスムーズにご対応できます"
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                this.lastDay = val.date

            }
            this.dayModal = false
        },
        daySelect(val) {
            this.dayModal = true
            this.first = val
        },
        async setEvent() {
            await d.sleep(10)
            this.setColor()
            for (let i = 0; i < document.getElementsByClassName('v-calendar-weekly__day').length; i++) {
                if (document.getElementsByClassName('v-calendar-weekly__day')[i].innerHTML.indexOf('空きなし') != -1) {
                    document.getElementsByClassName('v-calendar-weekly__day')[i].classList.add('noevent')
                }
            }
            // if (this.toMonth == this.month) {
            //     for (let i = 0; i < document.getElementsByClassName('v-calendar-weekly__day').length; i++) {
            //         if (document.getElementsByClassName('v-calendar-weekly__day')[i].innerText.indexOf(moment().format("M")+" / 1") != -1) {
            //             document.getElementsByClassName('v-calendar-weekly__day')[i].classList.add('noevent')
            //             break;
            //         }
            //     }
            // }

        },
    },
    watch: {
        async dayModal(val) {
            if (val) {
                this.error = ""
                this.warning = ""
            }
            this.setEvent()
        },
        async booking(val) {
            if (val == 2) {
                await d.sleep(10)
                this.setEvent()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/contact.scss";
</style>
